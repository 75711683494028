import Class from 'oyat/Class';
import Application from './Application';
import Helpers from './Helpers';
import { doGET } from './API';

export default Class.extend({
    __construct: function(view, options) {
        this.view = view;
        this.type = options.type;
        this.mode = options.mode;

        this.OL = false;
        this.media = [];
        this.filters = {};

        Application.bus.on(this.type + '.BrandSelected', (brand) => {
            this.view.clear();
            this.view.setBrand(brand);
        });

        Application.bus.on(this.type + '.ProductLineSelected', (data) => {
            this.view.clear();
        });

        Application.bus.on('SiteUpdated', () => {
            this.view.clear();
        });

        Application.bus.on(this.type + '.SearchOL', (OL) => {
            this.OL = OL;

            doGET('Media', {
                payload: { mode: this.mode, orderableLevelID: OL.id },
                onSuccess: (data) => {
                    this.media = data.records;
                    this.filters = {};
                    this.filter();
                }
            });
        });

        view.on('ToggleFilter', (filter) => {
            if(this.filters[filter.type]) {
                delete this.filters[filter.type];
            }
            else {
                this.filters[filter.type] = filter.value;
            }

            this.filter();
        });

        view.on('Download', function(volume) {
            Helpers.download({
                volumeID: volume.id
            });
        });
    },
    filter: function() {
        this.view.clear();

        // TODO sort language
        // TODO sort 

        var filtersValues = {
            type: [],
            OS: [],
            language: []
        };

        var availableFilters = {
            type: {},
            OS: {},
            language: {}
        };

        var filtered = [];

        this.media.forEach((medium) => {
            // NOTE just in case but no medium should be indexable if it does not have volumes
            medium.myVolumes = medium.myVolumes || [];

            var match = true;

            for(var type in this.filters) {
                if(medium[type] !== this.filters[type]) {
                    match = false;
                }
            }

            if(medium.type) {
                if(filtersValues.type.indexOf(medium.type) === -1) {
                    availableFilters.type[medium.type] = {
                        selected: this.filters.type === medium.type,
                        count: 0
                    };

                    filtersValues.type.push(medium.type);
                }

                if(match) {
                    availableFilters.type[medium.type].count++;
                }
            }

            if(medium.OS) {
                if(filtersValues.OS.indexOf(medium.OS) === -1) {
                    availableFilters.OS[medium.OS] = {
                        selected: this.filters.OS === medium.OS,
                        count: 0
                    };

                    filtersValues.OS.push(medium.OS);
                }

                if(match) {
                    availableFilters.OS[medium.OS].count++;
                }
            }

            if(medium.language) {
                if(filtersValues.language.indexOf(medium.language) === -1) {
                    availableFilters.language[medium.language] = {
                        selected: this.filters.language === medium.language,
                        count: 0
                    };

                    filtersValues.language.push(medium.language);
                }

                if(match) {
                    availableFilters.language[medium.language].count++;
                }
            }

            if(match) {
                filtered.push(medium);
            }
        });

        var typeWeights = {
            PDIR: 100,
            Code: 90,
            Content: 80,
            Doc: 70,
            Education: 60,
            'CAA API': 50,
            DataBase: 40
        };

        filtered.sort(function(a, b) {
            var weightA = typeWeights[a.type];
            var weightB = typeWeights[b.type];

            if(weightA == weightB) {
                return a.description == b.description ? 0 : (a.description < b.description ? -1 : 1);
            }
            else {
                return weightA == weightB ? 0 : (weightA < weightB ? 1 : -1);
            }
        });

        filtersValues.type.sort(function(a, b) {
            var weightA = typeWeights[a];
            var weightB = typeWeights[b];
            return weightA == weightB ? 0 : (weightA < weightB ? 1 : -1);
        });

        filtersValues.OS.sort();
        filtersValues.language.sort();

        var realFilters = {};

        for(var type in filtersValues) {
            if(!filtersValues.hasOwnProperty(type)) {
                continue;
            }

            var c = filtersValues[type].length;

            if(c == 0) {
                continue;
            }

            realFilters[type] = {};

            for(var i = 0; i < c; i++) {
                var value = filtersValues[type][i];
                realFilters[type][value] = availableFilters[type][value];
            }
        }

        this.view.update(this.OL, filtered, realFilters);
    }
});
