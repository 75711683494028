import Class from 'oyat/Class';
import Helpers from 'oyat/Helpers'
import Application from '../Application';
import { doGET, doPOST, doPUT } from '../API';

export default Class.extend({
	__construct: function(view) {
		this.calling = false;
		this.view = view;
        this.rating = null;
		
		view.on('Render', this.loadRating.bind(this));
		view.on('SaveRating', this.saveRating.bind(this));
		
		this.view.commentView.elements.body.childNodes[3].addEventListener('input', function(e) {
			this.view.updateCommentLength();
		}.bind(this), false);
	},
	
	loadRating: function () {
        doGET('Rating/last', {
            onException: () => {
                // NOTE no rating
            },
            onSuccess: (ratingData) => {
				if (ratingData) {
                    this.rating = ratingData;
                    this.view.rating = ratingData;
					
					this.view.showValue()
					this.view.showComment();
					this.view.updateCommentLength();
				}
			}
		});
	},
	
	saveRating: function() {
		if (!this.calling) {
			this.calling = true;
			
			var payload = {
				value: this.view.rating.value,
				comment: this.view.commentView.elements.body.childNodes[3].value
			}
			
			if(this.rating?.id) {
                doPUT('Rating/' + this.rating.id, {
                    payload: payload,
                    onSuccess: () => {
                        Application.notifier.notify('Rating done successfully', {
                            timeout: 5000
                        });
                        
                        this.calling = false;
                        this.view.submitButton.removeType('rating-submitted');
                        Application.closeModal();
                    }
                });
			}
            else {
                doPOST('Rating', {
                    payload: payload,
                    onSuccess: () => {
                        Application.notifier.notify('Rating done successfully', {
                            timeout: 5000
                        });
                        
                        this.calling = false;
                        this.view.submitButton.removeType('rating-submitted');
                        Application.closeModal();
                    }
                });
            }
		}
	}
});
