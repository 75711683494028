import Class from 'oyat/Class';
import MediaListView from '../MediaListView';
import MediaListViewController from '../MediaListViewController';
import Application from '../Application';
import User from '../User';
import { doGET, doPATCH } from '../API';

export default Class.extend({
    __construct(view) {
        this.view = view;
        this.selection = null;

        this.view.on('Render', () => {
            var subView = new MediaListView();
            new MediaListViewController(subView, { type: 'FreeDownload', mode: 'free' });
            view.add(subView);

            this.loadVersions();
        });

        this.view.on('ProductLineSelected', (selection) => {
            this.selection = selection;

            if((Application.session.user.flags & User.FLAG_CLOSA_ACCEPTED) == 0) {
                this.view.setData({ showCLOSA: true });
            }
            else {
                Application.bus.emit('FreeDownload.BrandSelected', selection.brand);
                Application.bus.emit('FreeDownload.ProductLineSelected', selection);
                this.loadOrderableLevels();
            }
        });

        this.view.on('AcceptCLOSA', () => {
            doPATCH('User', {
                payload: { CLOSAAccepted: true },
                onSuccess: () => {
                    Application.loadSession();
                    this.view.setData({ showCLOSA: false });
                    Application.bus.emit('FreeDownload.BrandSelected', this.selection.brand);
                    Application.bus.emit('FreeDownload.ProductLineSelected', this.selection);
                    this.loadOrderableLevels();
                }
            });
        });

        this.view.on('RefuseCLOSA', () => {
            this.view.setData({ showCLOSA: false });
        });

        this.view.on('SearchOL', (OL) => {
            Application.bus.emit('FreeDownload.SearchOL', OL);
        });
    },
    loadVersions: function() {
        this.view.setData({ loading: true });

        doGET('Versions', {
            payload: { mode: 'free' },
            onSuccess: (data) => {
                var versionIDs = data.records.map(version => version.id);
                var productLines = [];

                BRANDS.forEach((brand) => {
                    var allowed = false;

                    for(var i in brand.lines) {
                        var productLine = brand.lines[i];
                        var match = false;

                        for(var j in productLine.versions) {
                            var version = productLine.versions[j];

                            if(versionIDs.indexOf(version) !== -1) {
                                match = true;
                            }
                        }

                        if(match) {
                            productLine.brand = { title: brand.title, type: brand.type };
                            productLines.push(productLine);
                        }
                    }
                });

                this.view.setData({ productLines: productLines, loading: false });
            }
        });
    },
    loadOrderableLevels: function() {
        doGET('OrderableLevels', {
            payload: { mode: 'free', versionIDs: this.selection.versions },
            onSuccess: (OLData) => {
                var OLs = OLData.records;
                var releases = [];
                var levels = [];
                var hotfixes = [];
                var releaseIDs = [];

                OLs.forEach(function(anOL) {
                    var releaseID = anOL.level.release.id;

                    if(releaseIDs.indexOf(releaseID) === -1) {
                        releaseIDs.push(releaseID);
                        releases.push(anOL.level.release);
                    }

                    levels.push(anOL);
                });

                this.view.setData({
                    productLine: this.selection,
                    releases,
                    levels
                });
            }
        });
    }
});
