import XHR from 'oyat/XHR.js';
import Helpers from 'oyat/Helpers.js';
import Application from './Application.js';
import './assets/style/loader.css';

var loader = {
    mask: document.body.appendChild(Helpers.Element.create('div', {
        className: 'loader-mask'
    })),
    progress: document.body.appendChild(Helpers.Element.create('div', {
        className: 'loader-progress'
    })),
    interval: false,
    completion: 0,
    pending: 0,
    start: function() {
        if(loader.pending === 0) {
            Helpers.Element.setAttributes(loader.progress, { style: 'transition:none;width:0%;height:5px' });
            Helpers.Element.setAttributes(loader.mask, { style: 'display:block' });

            loader.interval = window.setInterval(function() {
                if(loader.completion > 80) {
                    loader.completion += 0.1;
                }
                else if(loader.completion > 50) {
                    loader.completion += 1;
                }
                else {
                    loader.completion += 10;
                }

                loader.completion = Math.min(95, loader.completion);
                Helpers.Element.setAttributes(loader.progress, { style: 'transition:width 0.25s ease-in,height 0.5s linear 0.5s;width:' + loader.completion + '%;height:5px' });
            }, 25);
        }

        loader.pending++;
    },
    end: function() {
        loader.pending--;

        if (loader.pending === 0) {
            window.clearInterval(loader.interval);
            loader.interval = false;
            loader.completion = 0;

            Helpers.Element.setAttributes(loader.progress, { style: 'transition:width 0.25s ease-in,height 0.5s linear 0.5s;width:100%;height:0' });
            Helpers.Element.setAttributes(loader.mask, { style: '' });
        }
    }
};

var moduleOptions = {
    passportURL: import.meta.env.VITE_PASSPORT_URL,
    serviceURL: import.meta.env.VITE_SERVICE_URL,
    baseURL: import.meta.env.VITE_API_BASE_URL
};

function callREST(options) {
    options = options || {};
    options.onException = options.onException || function(e) {
        Application.notifier.notify(e.message, { className: 'error' });
        console.log(e);
    };

    options.onSuccess = options.onSuccess || function() { };

    var myUUID = Math.random().toString(36).slice(2);

    loader.start();

    var URL = moduleOptions.baseURL.replace(/\/+$/, '');
    URL += '/' + options.path;

    var search = new URLSearchParams();

    if(options.__ticket) {
        search.set('ticket', options.__ticket);
    }

    if(options.payload && (options.method == 'GET' || options.method == 'DELETE')) {
        search.set('options', JSON.stringify(options.payload));
    }

    URL += '?' + search.toString();

    XHR.callBasic(URL, {
        withCredentials: true,
        method: options.method,
        headers: {
            'Content-Type': 'application/json'
        },
        postBody: options.method == 'GET' || options.method == 'DELETE' ? null : JSON.stringify(options.payload),
        on401: function(transport) {
            if(options.__ticket) {
                throw new Error('Authentication with CAS ticket failed');
            }

            retrieveCASTicket(options);
            loader.end();
        },
        on200: function(transport) {
            loader.end();

            try {
                var data = JSON.parse(transport.responseText || 'null');
                options.onSuccess(data);
            } catch (e) {
                options.onException({ message: e.message, code: 0 });
            }
        },
        on400: function(transport) {
            loader.end();

            try {
                var error = JSON.parse(transport.responseText);
                options.onException({ message: error.message, code: error.code });
            } catch(e) {
                options.onException({ message: e.message, code: 0 });
            }
        },
        onSuccess: function(transport) {
            loader.end();
            options.onException(new Error('Unexpected HTTP code ' + transport.status));
        },
        onFailure: function(transport) {
            loader.end();
            options.onException(new Error('Unexpected HTTP code ' + transport.status));
        }
    });
}

function retrieveCASTicket(options) {
    if(!moduleOptions.passportURL) {
        throw new Error('No passport URL defined');
    }

    if(!moduleOptions.serviceURL) {
        throw new Error('No service URL defined');
    }

    var passportURL = moduleOptions.passportURL.replace(/\/+$/, '') + '/cas/login/?service=' + encodeURI(moduleOptions.serviceURL);

    XHR.callBasic(passportURL, {
        withCredentials: true,
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        on401: function(transport) {
            var previous = window.location.toString();
            window.location.assign(moduleOptions.passportURL.replace(/\/+$/, '') + '/cas/login/?method=cookie&service=' + encodeURIComponent(previous));
        },
        on200: function(transport) {
            try {
                var response = JSON.parse(transport.responseText);

                options.__ticket = response.access_token;
                callREST(options);
            }
            catch(e) {
                options.onException(e);
            }
        },
        onSuccess: function(transport) {
            options.onException(new Error('Unexpected Passport error'));
        },
        onFailure: function(transport) {
            options.onException(new Error('Unexpected Passport error'));
        }
    });
}

export function doGET(path, options) {
    options.method = 'GET';
    options.path = path;
    callREST(options);
}

export function doPATCH(path, options) {
    options.method = 'PATCH';
    options.path = path;
    callREST(options);
}

export function doDELETE(path, options) {
    options.method = 'DELETE';
    options.path = path;
    callREST(options);
}

export function doPOST(path, options) {
    options.method = 'POST';
    options.path = path;
    callREST(options);
}

export function doPUT(path, options) {
    options.method = 'PUT';
    options.path = path;
    callREST(options);
}
