import Application from '../Application';
import View from 'oyat/UI/View';
import './style.css';

const MAXIMUM_VALUE = 5;
const MINIMUM_GREAT_VALUE = 4;

export default View.extend({
	__construct: function() {
		this.__parent();
		this.addType('rating-view');
		
		this.rating = {
			comment: '',
			value: 0
		};
		
		var closeButton = this.add(new View());
		closeButton.addType('close-button fa fa-window-close');
		closeButton.on('Click', function() {
			Application.closeModal();
		});
		
		// Add components to the main view
		this.titleView = this.add(new View());
		this.valueView = this.add(new View());
		this.commentView = this.add(new View());
		this.submitButton = this.add(new View());
		
		// Initialize title subview
		this.titleView.setHTML('<h1>Rate software.3ds.com</h1><h3>Please rate your navigation and download experience on software.3ds.com</h3>');
		this.titleView.addType('rating-title');
		
		// Initialize value subview
		this.valueView.addType('rating-value');
		
		for (let i = MAXIMUM_VALUE; i > 0; i --) {
			let star = this.valueView.add(new View());
			
			star.addType('rating-star fa fa-lg');
			
			star.on('Click', function() {
				this.rating.value = i;
				
				this.showValue();
				this.showComment();
			}.bind(this));
		}
		
		// Initialize comment subview
		this.commentView.setHTML('<h2>Comment</h2><div>Your experience does not seem satisfactory to you, please take a few moment to give us some more details on what could be better.</div><div>Your comment will allow us to continue to improve the navigation and the download experience, thank you !</div><textarea maxlength="255" rows="3"></textarea><p class="rating-length">0/255 characters</p><p class="rating-disclaimer">This is not a way to contact the support, if you have any support needs please contact your local <a href="https://www.3ds.com/support/" target="_blank">support</a></p>');
		this.commentView.addType('rating-comment');
		this.commentView.hide();
		
		// Initialize submit button
		this.submitButton.setHTML('Submit');
		this.submitButton.addType('rating-button');
		
		this.submitButton.on('Click', function() {
			this.submitButton.addType("rating-submitted");
			this.emit('SaveRating');
		}.bind(this));
    },
	
	showComment: function() {
		if (this.rating.value > 0 && this.rating.value < MINIMUM_GREAT_VALUE) {
			if (this.rating.comment) {
				this.commentView.elements.body.childNodes[3].value = this.rating.comment;
				this.updateCommentLength();
			}
			
			this.commentView.show();
		}
		else {
			this.commentView.hide();
		}
	},
	
	showValue: function() {
		for (let i = 0; i < MAXIMUM_VALUE; i ++)
			this.valueView.children[i].removeType("rating-selected");
		
		if (this.rating.value > 0 && this.rating.value < MAXIMUM_VALUE + 1)
			this.valueView.children[MAXIMUM_VALUE - this.rating.value].addType("rating-selected");
	},
	
	updateCommentLength: function() {
		let length = this.commentView.elements.body.childNodes[3].value.length;
			
		this.commentView.elements.body.childNodes[4].innerHTML = length + '/255 characters';
	}
});
