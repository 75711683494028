import Class from 'oyat/Class';
import Application from '../Application';
import SitesWindow from '../SitesWindow';
import RatingView from '../Rating/View';
import RatingViewController from '../Rating/ViewController';
import { doGET, doPATCH, doDELETE } from '../API';

export default Class.extend({
    __construct: function(view) {
        Application.bus.on('SessionLoaded', function() {
            view.setSession(Application.session);
        });

        view.setSession(Application.session);

        view.on('GoToOrders', function() {
            Application.bus.emit('ChangePage', { key: 'Orders' });
        });

        view.on('GoToFreeDownload', function() {
            Application.bus.emit('ChangePage', { key: 'FreeDownload' });
        });

        view.on('GoToSupportedSoftware', function() {
            Application.bus.emit('ChangePage', { key: 'SupportedSoftware' });
        });
		
		view.on('OpenHelp', function() {
			window.open('/help.pdf', '_blank');
		});
		
		view.on('OpenRating', function() {
			let ratingView = new RatingView();
			new RatingViewController(ratingView);
			
			Application.modalLayer.show();
			Application.modalLayer.add(ratingView);
		});

        view.on('OpenSites', function() {
            var locationW = Application.viewport.add(new SitesWindow(Application.session.sites));
            locationW.show();

            locationW.on('Cancel', function() {
                Application.viewport.remove(locationW);
            });

            locationW.on('ChangeSite', function(site) {
                // TODO set a message on application bus and listen to application bus
                doPATCH('Session', {
                    payload: { siteID: site.id },
                    onSuccess: function() {
                        Application.viewport.remove(locationW);
                        Application.loadSession(() => {
                            Application.bus.emit('SiteUpdated');
                        });
                    }
                });
            });
        });

        view.on('Logout', function() {
            doDELETE('Session', {
                onSuccess: function(data) {
                    window.location = data.logoutURL;
                }
            });
        });

        Application.bus.on('ChangePage', function(data) {
            view.buildNavigation(data.key);
        });
    }
});
