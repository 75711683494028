import Class from 'oyat/Class';
import Application from '../Application';
import Helpers from '../Helpers';
import { doGET } from '../API';

export default Class.extend({
    __construct: function(view, data) {
        this.view = view;
        
        this.view.on('ShowOrders', this.loadOrders.bind(this));

        Application.bus.on('SiteUpdated', this.loadOrders.bind(this));

        this.view.on('ShowOrder', (orderKey) => {
            doGET('Order/' + btoa(orderKey), {
                onSuccess: (data) => {
                    // TODO if order head office is different from session head office change site and notify use

                    if(data.consumable) {
                        this.view.showConsent(data.order);
                    }
                    else {
                        this.view.showOrder(data.order, data.lines);
                    }
                }
            });
        });

        if(data && data.orderKey) {
            this.view.emit('ShowOrder', data.orderKey);
        }
        else {
            this.view.on('Render', () => {
                this.loadOrders();
            });
        }

        view.on('Download', function(data) {
            Helpers.download({
                volumeID: data.volume.id,
                ticketID: data.ticket.id
            });
        });
    },
    loadOrders: function() {
        doGET('Orders', {
            onSuccess: (data) => {
                var orders = data.records;
                var byType = {};

                orders.forEach(function(order) {
                    var units = [];

                    order.myLines = order.myLines || [];

                    order.myLines.forEach(function(line) {
                        var unit = line.logicalUnitName;

                        if(units.indexOf(unit) === -1) {
                            units.push(unit);
                        };
                    });

                    order.units = units.join(', ');

                    if(!byType[order.type]) {
                        byType[order.type] = [];
                    }

                    byType[order.type].push(order);
                });

                this.view.showOrders(byType.CUS || [], byType.DSX || []);
            }
        });
    }
});
