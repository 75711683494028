import TopView from './Top/View';
import TopViewController from './Top/ViewController';
import MainView from './Main/View.js';
import MainViewController from './Main/ViewController.js';
import Helpers from 'oyat/Helpers';
import Class from 'oyat/Class';
import Label from 'oyat/UI/Label';
import View from 'oyat/UI/View';
import Viewport from 'oyat/UI/Viewport';
import Notifier from 'oyat/UI/Notifier';
import EventBus from 'oyat/EventBus';
import { doGET } from './API';
import './assets/style/filters.css';

var Application = Class.extend({
    __construct: function() {
        this.bus = new EventBus();
        this.viewport = new Viewport('viewport');
        this.notifier = this.viewport.add(new Notifier());
        this.notifier.addType('fixed');

		this.modalLayer = new View();
		this.viewport.add(this.modalLayer);
		this.modalLayer.hide();
		
		this.modalLayer.elements.body.id = 'modal-layer';
		
		this.bus.on('CloseModal', this.closeModal.bind(this));
    },
    run: function() {
        this.loadSession(() => {
            this.loadBrands();
        });
    },
    loadBrands: function() {
        doGET('Brands', {
            onSuccess: (data) => {
                window.BRANDS = data.records;

                var topView = new TopView();
                this.viewport.add(topView);
                new TopViewController(topView);

                var mainView = new MainView();
                new MainViewController(mainView);
                this.viewport.add(mainView);
            }
        });
    },
    loadSession: function(callback) {
        doGET('Session', {
            onSuccess: (session) => {
                this.session = session;
                this.bus.emit('SessionLoaded');

                if(callback) {
                    callback();
                }
            }
        });
    },
	closeModal: function() {
		this.modalLayer.clear();
		this.modalLayer.hide();
	}
});

let instance = new Application();
export default instance;
