import View from 'oyat/UI/View';
import Label from 'oyat/UI/Label';
import Link from 'oyat/UI/Link';
import HBox from 'oyat/UI/HBox';
import Helpers from '../../Helpers';
import MediaView from '../../Common/Media/View';
import './style.css';

export default View.extend({
    __construct: function() {
        this.__parent();
        this.addType('media-list-view');
    },
    setBrand: function(brand) {
        this.brand = brand;
    },
    update: function(OL, media, filters) {
        this.clear();

        if(OL.warning) {
            var warning = OL.warning.replace(/KB@([A-Z0-9]+)/, '<a href="https://kb.dsxclient.3ds.com/mashup-ui/page/document?q=$1" target="_blank">$1</a>');
            warning = warning.replace(/URL@([^@]+)@(https:\/\/[^\s]+)/, '<a href="$2" target="_blank">$1</a>');

            var warningView = this.add(new View());
            warningView.addType('warning');
            warningView.setHTML(warning);
        }

        var hbox = this.add(new HBox());
        var listView = hbox.add(new View(), { width: '1048px' });
        listView.addType('media-list');

        var filtersView = hbox.add(new View(), { width: '200px' });
        filtersView.addType('media-filters');

        for(var group in filters) {
            var titleView = filtersView.add(new View());
            titleView.addType('group-title');
            titleView.addType('brand-' + this.brand.type);

            var groupView = filtersView.add(new View());
            groupView.addType('group');

            switch(group) {
                case 'type': titleView.add(new Label('Type')); break;
                case 'OS': titleView.add(new Label('Operating System')); break;
                case 'language': titleView.add(new Label('Language')); break;
                default: titleView.add(new Label('Others'));
            }

            for(var value in filters[group]) {
                var info = filters[group][value];

                var line = groupView.add(new HBox());
                line.addType('filter');

                if(info.selected) {
                    line.addType('selected');
                }

                var text = value;

                switch(group + ':' + value) {
                    case 'type:Code': text = 'Software'; break;
                    case 'type:PDIR': text = 'Program Directory'; break;
                    case 'type:Doc': text = 'Documentation'; break;
                    case 'type:DataBase': text = 'Database'; break;
                    case 'OS:AllOS': text = 'Multiple OS'; break;
                }

                line.add(new Label(text), { width: '70%' });

                if(info.count !== 0) {
                    line.addType('enabled');
                    line.add(new Label(info.count.toString()));
                    line.on('Click', this.emit.bind(this, 'ToggleFilter', { type: group, value: value }));
                }
                else {
                    line.addType('disabled');
                }
            }
        }

        media.forEach(function(anOM) {
            var subView = listView.add(new MediaView(anOM, OL, this.brand.type));
            subView.on('Download', this.emit.bind(this, 'Download'));
        }.bind(this));
    }
});
