import View from 'oyat/UI/View';
import Label from 'oyat/UI/Label';
import './style.css';

export default View.extend({
    __construct: function() {
        this.__parent();
        this.addType('main-view');
        this.screens = {};
    },
    hasScreen: function(key) {
        return !!this.screens[key];
    },
    addScreen: function(view, key) {
        view.hide();
        this.screens[key] = view;
        return this.add(view);
    },
    toggleScreen: function(key) {
        for (var i in this.screens) {
            if (this.screens.hasOwnProperty(i)) {
                this.screens[i].hide();
            }
        }

        this.screens[key].show();
    }
});
