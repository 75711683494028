import Class from 'oyat/Class';
import Application from '../Application';
import Helpers from '../Helpers';
import SitesWindow from '../SitesWindow';
import * as Screens from '../Screens';

export default Class.extend({
    __construct: function(view) {
        this.view = view;

        Application.bus.on('ChangePage', function(data) {
            var key = data.key;
            var options = data.options;

            if (!this.view.hasScreen(key)) {
                var subView = new Screens[key + 'View'];
                new Screens[key + 'ViewController'](subView, options);
                this.view.addScreen(subView, key);
            }

            this.view.toggleScreen(key);
        }.bind(this));

        var matches = location.pathname.match(/^\/orders\/id\/-(.*)-$/);

        if(!!matches) {
            if(history && history.replaceState) {
                history.replaceState(null, '', '/');
            }

            Application.bus.emit('ChangePage', {
                key: 'Orders',
                options: {
                    orderKey: matches[1]
                }
            });
        }
        else {
            Application.bus.emit('ChangePage', { key: 'SupportedSoftware' });

            if(location.pathname !== '/') {
                Helpers.download({
                    path: location.pathname
                });
            }
        }
    }
});
