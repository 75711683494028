import Application from '../Application';
import Class from 'oyat/Class';
import ListView from './List/View';
import ListViewController from './List/ViewController';
import { doGET } from '../API';

export default Class.extend({
    __construct: function(view) {
        this.view = view;
        this.type = 'SupportedSoftware';
        this.method = 'supported';

        view.on('Render', () => {
            var listView = new ListView();
            new ListViewController(listView, this.type, this.method);
            this.view.add(listView);

            this.onSiteUpdated();
        });

        Application.bus.on('SiteUpdated', this.onSiteUpdated.bind(this));
       
        view.on('BrandSelected', this.onBrandSelected.bind(this));
        view.on('ProductLineSelected', this.onProductLineSelected.bind(this));

        view.on('ReleaseSelected', (data) => {
            doGET('OrderableLevels', {
                payload: { mode: 'supported', releaseID: data.id },
                onSuccess: function(response) {
                    var OLs = response.records;
                    var levels = [];
                    var hotfixes = [];

                    OLs.forEach(function(anOL) {
                        if(anOL.level.hotfix === 1) {
                            hotfixes.push(anOL);
                        }
                        else {
                            levels.push(anOL);
                        }
                    });

                    view.update({ levels, hotfixes });
                }
            });
        });

        view.on('OrderableLevelSelected', (data) => {
            doGET('OrderableLevels', {
                payload: { mode: 'supported', hotfixOfID: data.level.id },
                onSuccess: (response) => {
                    this.view.update({ hotfixes: response.records });

                    if(response.records.length === 0) {
                        Application.bus.emit(this.type + '.SearchOL', data);
                    }
                }
            });
        });

        view.on('SearchOL', (OL) => {
            Application.bus.emit(this.type + '.SearchOL', OL);
        });

        Application.bus.on(this.type + '.SearchOL', function() {
            // TODO hide product lines
        });
    },
    onSiteUpdated: function() {
        this.view.update({
            loading: true,
            alerts: [],
            selectedBrand: null,
            selectedProductLine: null,
            releases: [],
            levels: [],
            hotfixes: [],
        });

        doGET('Versions', {
            payload: { mode: 'supported' },
            onSuccess: (data) => {
                this.view.update({ loading: false });

                if(data.records.length === 0) {
                    this.view.update({ alerts: data.errors });
                }
                else {
                    let allowedVersions = data.records.map(version => version.id);

                    // NOTE find the default brand to display
                    var defaultBrand = false;

                    BRANDS.forEach(function(brand) {
                        var allowed = false;

                        loop1:
                        for(var i in brand.lines) {
                            var productLine = brand.lines[i];

                            for(var j in productLine.versions) {
                                var version = productLine.versions[j];

                                if(allowedVersions.indexOf(version) !== -1) {
                                    allowed = true;
                                    break loop1;
                                }
                            }
                        }

                        if(allowed && defaultBrand === false) {
                            defaultBrand = brand;
                        }
                    }.bind(this));

                    this.view.update({ versions: allowedVersions });

                    this.onBrandSelected(defaultBrand);
                    Application.bus.emit(this.type + '.Loaded');
                }
            }
        });
    },
    onBrandSelected: function(selectedBrand) {
        Application.bus.emit(this.type + '.BrandSelected', selectedBrand);

        this.view.update({ selectedBrand, selectedProductLine: null, releases: [], levels: [], hotfixes: [] });

        if(selectedBrand.lines.length === 1) {
            this.onProductLineSelected(selectedBrand.lines[0]);
        }
    },
    onProductLineSelected: function(productLine) {
        Application.bus.emit(this.type + '.ProductLineSelected', productLine);

        this.view.update({ selectedProductLine: productLine });

        if(productLine.externalURL) {
            window.open(productLine.externalURL, '_blank');
        }
        else {
            doGET('Releases', {
                payload: { mode: 'supported', versionIDs: productLine.versions },
                onSuccess: (response) => {
                    this.view.update({ releases: response.records });
                }
            });
        }
    }
});
