import Application from './Application';
import { doGET } from './API';

export default {
    formatSize: function(size) {
        if(size >= 1000 && size < 1024) {
            size = 1024;
        }

        if(size >= 1024) {
            return (size / 1024).toFixed(1) + ' GB';
        }
        else {
            return size + ' MB';
        }
    },
    formatDate: function(date) {
        var text = date.getDate() + ' ';
        text += ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()];
        text += ' ' + date.getFullYear();
        return text;
    },
    download: function(data) {
        let key = data.volumeID || data.path.replace(/^\//, '');
        let payload = {};

        if(data.ticketID) {
            payload.ticketID = data.ticketID;
        }
        
        doGET('Volume/' + key, {
            payload: payload,
            onSuccess: function(data) {
                window.open(data.fileURL, '_blank');
            }
        });
    }
};
