import View from 'oyat/UI/View';
import Link from 'oyat/UI/Link';
import Label from 'oyat/UI/Label';
import HBox from 'oyat/UI/HBox';
import Helpers from '../../Helpers';
import './style.css';

export default View.extend({
    __construct: function(anOM, anOL, brand) {
        this.__parent();
        this.addType('media-view');

        var description = '<b>' + anOM.description + '</b>';
        description += ' - ' + anOL.name;

        if(anOM.language) {
            description += ' - ' + anOM.language;
        }

        if(anOM.OS) {
            description += ' - ' + anOM.OS;
        }

        var mainLine = this.add(new View());
        mainLine.addType('media-main');

        var mediaTypeCell = mainLine.add(new View(), { width: '10%' });
        mediaTypeCell.addType('media-type-cell');

        var secondCell = mainLine.add(new View(), { width: '75%' });
        secondCell.addType('media-desc-cell');

        var fourthCell = mainLine.add(new View(), { width: '15%' });
        fourthCell.addType('media-size-cell');

        mediaTypeCell.setHTML('<div class="icon ' + anOM.type.replace(' ', '').toLowerCase() + '" title="' + anOM.type + '"></div>');

        var descriptionV = secondCell.add(new View());
        descriptionV.setHTML(description);
        descriptionV.addType('media-description');

        var totalSize = 0;
        
        var quickVolumesV = secondCell.add(new View(), { width: '75%' });
        quickVolumesV.addType('quick-downloads');

        for(var i = 0, c = anOM.myVolumes.length; i < c; i++) {
            var volume = anOM.myVolumes[i];
            var fileURL = '/' + volume.service + '/' + volume.destination;

            var downloadView = quickVolumesV.add(new View());
            downloadView.addType('quick-download');

            var iconV = downloadView.add(new View());
            iconV.addType('download');
            iconV.setHTML('<i class="fa fa-download"></i>');
            iconV.on('Click', this.emit.bind(this, 'Download', volume));

            var countV = downloadView.add(new View());
            countV.addType('count');
            countV.setText((i + 1) + '/' + c);

            totalSize += volume.size;
        }

        var detailsV = this.add(new View());
        detailsV.addType('details brand-' + brand);
        detailsV.hide();
        detailsV.hidden = true;
        detailsV.add(new Label('Volumes')).addType('header');

        for(var i = 0, c = anOM.myVolumes.length; i < c; i++) {
            var volume = anOM.myVolumes[i];
            var fileURL = '/' + volume.service + '/' + volume.destination;

            var line = detailsV.add(new HBox());
            line.addType('volume');
            line.add(new Link({ text: volume.destination.replace(/^.*\/(.*)$/, '$1') }), { width: '70%' })
                .on('Click', this.emit.bind(this, 'Download', volume));

            var subView = line.add(new View(), { width: '30%' });
            subView.addType('info');
            subView.setHTML('MD5:  ' + volume.md5 + '<br/>Size: ' + Helpers.formatSize(volume.size));
        }

        detailsV.add(new Label('References: OLID ' + anOL.id + ' / OMID ' + anOM.id)).addType('tiny');

        fourthCell.add(new Label(Helpers.formatSize(totalSize)), { width: '15%' });

        var link = fourthCell.add(new Link({ text: 'Show details' }));

        link.on('Click', function(currentDetailsV, currentLink) {
            if(currentDetailsV.hidden) {
                currentDetailsV.show();
            }
            else {
                currentDetailsV.hide();
            }

            currentDetailsV.hidden = !currentDetailsV.hidden;
            currentLink.setOptions({
                text: currentDetailsV.hidden ? 'Show details' : 'Hide details'
            });
        }.bind(this, detailsV, link));
    }
});
