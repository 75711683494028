import View from 'oyat/UI/View';
import Label from 'oyat/UI/Label';
import Link from 'oyat/UI/Link';
import HBox from 'oyat/UI/HBox';
import ComboBox from 'oyat/UI/ComboBox';
import './style.css';

export default View.extend({
    __construct: function() {
        this.__parent();
        this.addType('versions-view');

        this.state = {
            loading: true,
            alerts: [],
            versions: [],
            selectedBrand: null,
            selectedProductLine: null,
            releases: [],
            levels: [],
            hotfixes: []
        };

        this.loading = this.add(new Label('Loading your supported software...'));
        this.loading.addType('loading');

        this.alertV = this.add(new View());
        this.alertV.hide();

        this.legend = this.add(new View());
        this.legend.hide();
        this.legend.addType('header-legend');
        this.legend.setHTML('<div class="left">Multi-brand platform</div><div class="bar"></div><div class="right">Brand-related product lines</div>');

        this.header = this.add(new View());
        this.header.hide();
        this.header.addType('header');

        this.logoV = this.add(new View());
        this.logoV.hide();
        this.logoV.addType('logo');
        this.logoV.setHTML('<div class="image"></div><div class="text"></div>');

        this.productLinesV = this.add(new View());
        this.productLinesV.hide();
        this.productLinesV.addType('product-lines');

        this.filtersV = this.add(new View());
        this.filtersV.hide();
        this.filtersV.addType('levels-filters');
    },
    addFilters: function() {
        var hbox = this.filtersV.add(new HBox());

        var firstCell = hbox.add(new View(), { width: '25%' });
        firstCell.add(new Label('Product line')).addType('top-lane');
        this.productLineLabel = firstCell.add(new Label(''));

        var secondCell = hbox.add(new View(), { width: '25%' });
        secondCell.add(new Label('Release')).addType('top-lane');
        this.releaseField = secondCell.add(new ComboBox({
            records: [],
            valueKey: 'id',
            legendKey: 'name'
        }));
        this.releaseField.on('Select', (data) => {
            if(data.record.id == -1) {
                this.releaseField.setRecords(this.state.releases);
                this.releaseField.selectIndex(0);
                this.releaseField.expand();
            }
            else {
                this.emit('ReleaseSelected', data.record);
            }
        });

        var thirdCell = hbox.add(new View(), { width: '25%' });
        thirdCell.add(new Label('Level')).addType('top-lane');
        this.levelField = thirdCell.add(new ComboBox({
            records: [],
            valueKey: 'id',
            legendKey: 'legend'
        }));
        this.levelField.on('Select', (data) => {
            if(data.record.id == -1) {
                this.levelField.setRecords(this.state.levels);
                this.levelField.selectIndex(0);
                this.levelField.expand();
            }
            else {
                this.emit('OrderableLevelSelected', data.record);
            }
        });

        var fourthCell = hbox.add(new View(), { width: '25%' });
        fourthCell.add(new Label('Fixes for this level')).addType('top-lane');

        this.noHotfixLabel = fourthCell.add(new Label('N/A'));

        this.hotfixField = fourthCell.add(new ComboBox({
            records: [],
            valueKey: 'id',
            legendKey: 'name'
        }));
        this.hotfixField.hide();
        this.hotfixField.on('Select', function(data) {
            if(data.record.id == -1) {
                var selectedLevel = this.levelField.getRecord();
            }
            else {
                var selectedLevel = data.record;
            }

            this.emit('SearchOL', selectedLevel);
        }.bind(this));
    },
    hasChanged: function(updates, key) {
        if(key in updates) {
            return JSON.stringify(updates[key]) !== JSON.stringify(this.state[key]);
        }

        return false;
    },
    update: function(updates) {
        let state = {
            ...this.state,
            ...updates
        };

        console.log('OLD STATE', this.state, 'UPDATES', updates, 'NEW STATE', state);

        if(this.hasChanged(updates, 'loading')) {
            let method = state.loading ? 'show' : 'hide';
            this.loading[method]();
        }

        if(this.hasChanged(updates, 'alerts')) {
            this.legend.hide();
            this.logoV.hide();
            this.header.hide();
            this.filtersV.hide();

            this.alertV.clear();
            this.alertV.hide();

            if(state.alerts.length !== 0) {
                this.alertV.show();

                var text = 'We did not find any Supported Software for this site. '; 
                text += state.alerts.join(' ');
                this.alertV.add(new Label(text)).addType('alert');
            }
        }

        if(this.hasChanged(updates, 'versions') || this.hasChanged(updates, 'selectedBrand')) {
            this.header.clear();

            var groups = BRANDS;

            groups.forEach(function(group) {
                var subView = this.header.add(new View());
                subView.setHTML(group.title);
                subView.addType('box-' + group.type + ' brand-' + group.type);

                var allowed = false;

                loop1:
                for(var i in group.lines) {
                    var productLine = group.lines[i];

                    for(var j in productLine.versions) {
                        var version = productLine.versions[j];

                        if(state.versions.indexOf(version) !== -1) {
                            allowed = true;
                            break loop1;
                        }
                    }
                }

                if(allowed) {
                    subView.on('Click', function(aGroup) {
                        this.emit('BrandSelected', aGroup);
                    }.bind(this, group));

                    if(state.selectedBrand && group.type === state.selectedBrand.type) {
                        subView.addType('active');
                    }
                }
                else {
                    subView.addType('box-disabled');
                }
            }.bind(this));

            this.legend.show();
            this.header.show();
        }

        if(updates.selectedBrand) {
            this.productLinesV.hide();
            this.productLinesV.clear();
            this.filtersV.hide();
            this.filtersV.clear();

            this.logoV.show();

            BRANDS.forEach(function(aBrand) {
                this.logoV.removeType('brand-' + aBrand.type);
                this.productLinesV.removeType('brand-' + aBrand.type);
                this.filtersV.removeType('brand-' + aBrand.type);
            }.bind(this));

            this.logoV.addType('brand-' + state.selectedBrand.type);
            this.productLinesV.addType('brand-' + state.selectedBrand.type);
            this.filtersV.addType('brand-' + state.selectedBrand.type);

            if(state.selectedBrand.lines.length > 1) {
                this.productLinesV.show();
                var i = 0;

                state.selectedBrand.lines.forEach(function(productLine) {
                    var cell = this.productLinesV.add(new View());
                    cell.addType('cell');

                    var view = cell.add(new View());
                    view.setHTML(productLine.title);
                    view.addType('product-line mod' + (i % 3));

                    var allowed = false;

                    for(var j in productLine.versions) {
                        var version = productLine.versions[j];

                        if(state.versions.indexOf(version) !== -1) {
                            allowed = true;
                            break;
                        }
                    }

                    if(allowed) {
                        view.addType('enabled');

                        view.on('Click', function() {
                            if(productLine.externalURL) {
                                window.open(productLine.externalURL, '_blank');
                            }
                            else {
                                this.emit('ProductLineSelected', productLine);
                            }
                        }.bind(this));
                    }
                    else {
                        view.addType('disabled');
                    }

                    i++;
                }.bind(this));
            }
        }

        if(this.hasChanged(updates, 'selectedProductLine')) {
            if(state.selectedProductLine) {
                this.productLinesV.hide();
                this.addFilters();
                this.filtersV.show();
                this.productLineLabel.setText(state.selectedProductLine.text || state.selectedProductLine.title);
            }
        }

        if(this.hasChanged(updates, 'releases')) {
            var partialReleases = state.releases.slice(0, 10);

            if(partialReleases.length !== state.releases.length) {
                partialReleases.push({ id: -1, name: '<div class="show-all">Show all</div>' });
            }

            this.releaseField.setRecords(partialReleases);

            if(partialReleases.length != 0) {
                this.releaseField.selectIndex(0);
            }
        }

        if(this.hasChanged(updates, 'levels')) {
            var forcePartial = false;

            state.levels.forEach(function(anOL) {
                var detectCFP = anOL.name.match(/\.CF[B-Z]\./); // NOTE wack because of V6 configured fix pack model for clients
                anOL.legend = detectCFP ? '<span style="color:orange">'  + anOL.name + '</span>' : anOL.name;

                if(detectCFP) {
                    forcePartial = true;
                }
            });

            if(state.levels.length > 10 || forcePartial) {
                var partialLevels = [];

                state.levels.forEach(function(level) {
                    if(!level.name.match(/\.CF[B-Z]\./)) { // NOTE wack because of V6 configured fix pack model for clients
                        partialLevels.push(level);
                    }
                });

                if(partialLevels.length > 10) {
                    partialLevels = partialLevels.slice(0, 10);
                    partialLevels.push(state.levels[state.levels.length - 1]);
                }

                partialLevels.push({ id: -1, legend: '<div class="show-all">Show all</div>' });
            }
            else {
                partialLevels = state.levels;
            }

            this.levelField.setRecords(partialLevels);

            if(partialLevels.length > 0) {
                this.levelField.selectIndex(0);
            }
        }

        if(this.hasChanged(updates, 'hotfixes')) {
            state.hotfixes.unshift({ id: -1, name: '-' });

            if(state.hotfixes.length == 1) {
                this.hotfixField.hide();
                this.noHotfixLabel.show();
            }
            else {
                this.hotfixField.show();
                this.noHotfixLabel.hide();
            }

            this.hotfixField.setRecords(state.hotfixes);
            
            if(state.levels.length > 0) {
                this.hotfixField.selectIndex(0);
            }
        }

        this.state = state;
    }
});
