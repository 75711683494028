import View from 'oyat/UI/View';
import Label from 'oyat/UI/Label';
import Link from 'oyat/UI/Link';
import HBox from 'oyat/UI/HBox';
import Helpers from '../Helpers';
import MediaView from '../Common/Media/View';
import './style.css';

export default View.extend({
    __construct: function() {
        this.__parent();
        this.addType('orders-view');
    },
    showOrders: function(limitedOrders, lifetimeOrders) {
        this.clear();

        if(limitedOrders.length !== 0) {
            var subView = this.add(new View());
            subView.addType('orders time-limited');
            subView.add(new Label('Time limited orders')).addType('title');

            var headerView = subView.add(new View());
            headerView.addType('header');

            var hbox = headerView.add(new HBox());
            hbox.add(new Label('Order reference'), { width: '20%' });
            hbox.add(new Label('Expiration'), { width: '20%' });
            hbox.add(new Label('Description'), { width: '60%' });

            limitedOrders.forEach(function(order) {
                var hbox = subView.add(new HBox());
                hbox.addType('order');

                hbox.add(new Link({ text: order.mediaOrderID }), { width: '20%' })
                    .on('Click', this.emit.bind(this, 'ShowOrder', order.key));
                hbox.add(new Label(Helpers.formatDate(new Date(order.expiredAt * 1000))), { width: '20%' });
                hbox.add(new Link({ text: order.units }), { width: '60%' })
                    .on('Click', this.emit.bind(this, 'ShowOrder', order.key));
            }.bind(this));
        }

        var subView = this.add(new View());
        subView.addType('orders lifetime');
        subView.add(new Label('Lifetime orders')).addType('title');

        if(lifetimeOrders.length === 0) {
            subView.add(new Label('We did not find any Order for this site.')).addType('alert');
        }
        else {
            var headerView = subView.add(new View());
            headerView.addType('header');

            var hbox = headerView.add(new HBox());
            hbox.add(new Label('Order reference'), { width: '20%' });
            hbox.add(new Label('Date'), { width: '20%' });
            hbox.add(new Label('Company'), { width: '60%' });

            lifetimeOrders.forEach(function(order) {
                var orderView = subView.add(new View());
                orderView.addType('order');

                var firstLine = orderView.add(new HBox());
                firstLine.add(new Link({ text: order.mediaOrderID }), { width: '20%' })
                    .on('Click', this.emit.bind(this, 'ShowOrder', order.key));

                var parts = order.createdAt.split(' ')[0].split('-');
                var createdAt = new Date(parts[0], parseInt(parts[1], 10) - 1, parts[2]);

                firstLine.add(new Label(Helpers.formatDate(createdAt)), { width: '20%' });
                firstLine.add(new Label(order.finalCustomerSite ? order.finalCustomerSite.name : ''), { width: '60%' });

                var secondLine = orderView.add(new HBox());
                secondLine.add(new View(), { width: '20%' }).setHTML('&nbsp;');
                secondLine.add(new Label(order.units), { width: '80%' }).addType('units');
            }.bind(this));
        }
    },
    showConsent: function(order) {
        this.clear();
        this.add(new Label('Media Order: ' + order.mediaOrderID)).addType('huge');

        if(order.consent) {
            this.add(new Label('By accepting to continue, you agree to use order contents in compliance with the terms of the agreement available <a href="#">here</a>, unless you are covered for such use by another specific agreement concluded with DS.'));
        }

        this.add(new Label('This site provides the downloads for this order. Click Yes only if you want to download and be linked as a user associated with this order.'));
        this.add(new Label('Do you want to continue to the download page?'));

        var hbox = this.add(new HBox());
        hbox.addType('actions');
        hbox.add(new Link({ text: 'Yes' }), { width: '10%' })
            .on('Click', this.emit.bind(this, 'Consume', order.key));
        hbox.add(new Link({ text: 'No' }), { width: '10%' })
            .on('Click', this.emit.bind(this, 'ShowOrders'));
    },
    showOrder: function(order, lines) {
        this.clear();

        this.add(new Link({ text: 'back to Orders' }))
            .on('Click', this.emit.bind(this, 'ShowOrders'));

        this.add(new Label(order.mediaOrderID)).addType('title');

        var previousLU = null;

        var listView = this.add(new View());
        listView.addType('media-list');

        lines.forEach(function(line) {
            if(line.logicalUnitName != previousLU) {
                var logicalUnitView = listView.add(new View());
                logicalUnitView.addType('logical-unit');
                logicalUnitView.add(new Label(line.logicalUnitName));

                previousLU = line.logicalUnitName;
            }

            var subView = listView.add(new MediaView(line.media, line.media.orderableLevel, 'none'));
            subView.on('Download', function(volume) {
                this.emit('Download', {
                    volume: volume,
                    ticket: order
                })
            }.bind(this));
        }.bind(this));
    }
});
