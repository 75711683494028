import View from 'oyat/UI/View';
import Label from 'oyat/UI/Label';
import HBox from 'oyat/UI/HBox';
import ComboBox from 'oyat/UI/ComboBox';
import Button from 'oyat/UI/Button';
import './style.css';

export default View.extend({
    __construct: function() {
        this.__parent();
        this.addType('freedownload-view');
        this.data = {
            loading: false,
            showCLOSA: false,
            productLines: [],
            productLine: null,
            releases: [],
            levels: []
        };

        this.loading = this.add(new Label('Loading free download...'));
        this.loading.addType('loading');

        this.productLines = this.add(new View());
        this.productLines.addType('product-lines');

        this.brand = this.add(new View());
        this.brand.addType('brand');

        this.filters = this.add(new View());
        this.filters.addType('levels-filters');

        this.closa = this.add(new View());
        this.closa.hide();
        this.closa.addType('closa');

        var wrapper = this.closa.add(new View());
        wrapper.addType('wrapper');

        var form = wrapper.add(new View());
        form.addType('form');

        var agreementURL = import.meta.env.VITE_API_BASE_URL;
        agreementURL = agreementURL.replace(/(\/*)$/, '');
        agreementURL += '/Agreement/FreeDownload';
        form.add(new View()).setHTML('<iframe src="' + agreementURL + '"></iframe>');

        var box = form.add(new HBox());
        box.add(new Button({ text: 'Accept the license agreement' }))
            .on('Click', () => {
                this.emit('AcceptCLOSA');
            });

        box.add(new Button({ text: 'Refuse' }))
            .on('Click', () => {
                this.emit('RefuseCLOSA');
            });
    },
    setData(data) {
        var update = { ...this.data, ...data };
        this.refresh(update);
        this.data = update;
    },
    refresh(update) {
        if(update.loading != this.data.loading) {
            if(update.loading) {
                this.loading.show();
            }
            else {
                this.loading.hide();
            }
        }

        if(update.productLines != this.data.productLines || update.productLine != this.data.productLine) {
            this.productLines.clear();

            for(var i in update.productLines) {
                const productLine = update.productLines[i];

                var box = this.productLines.add(new View());
                box.addType('product-line');
                box.addType('brand-' + productLine.brand.type);

                if(productLine == update.productLine) {
                    box.addType('selected');
                }

                var brand = box.add(new View());
                brand.addType('product-line-brand');
                brand.setHTML(productLine.brand.title);

                var title = box.add(new View());
                title.addType('product-line-title');
                title.setHTML(productLine.freeTitle ?? productLine.title);

                box.on('Click', () => {
                    this.emit('ProductLineSelected', productLine);
                });
            }
        }

        if(update.showCLOSA != this.data.showCLOSA) {
            if(update.showCLOSA) {
                this.closa.show();
            }
            else {
                this.closa.hide();
            }
        }
        else if(update.productLine != this.data.productLine) {
            this.refreshFilters(update);
        }
    },
    refreshFilters(update) {
        this.brand.clear();
        this.brand.show();

        BRANDS.forEach(function(aBrand) {
            this.brand.removeType('brand-' + aBrand.type);
        }.bind(this));

        this.brand.addType('brand-' + update.productLine.brand.type);
        this.brand.setHTML('<div class="image"></div><div class="text"></div>');

        this.filters.clear();
        this.filters.show();

        var filteredLevels = [];

        BRANDS.forEach((aBrand) => {
            this.filters.removeType('brand-' + aBrand.type);
        });

        this.filters.addType('brand-' + update.productLine.brand.type);

        var hbox = this.filters.add(new HBox());

        var firstCell = hbox.add(new View(), { width: '25%' });
        firstCell.add(new Label('Product line')).addType('top-lane');
        firstCell.add(new Label(update.productLine.text || update.productLine.title), { width: '10%' });

        var secondCell = hbox.add(new View(), { width: '25%' });
        secondCell.add(new Label('Release')).addType('top-lane');

        // TODO filter releases to be display only if they have at least one level

        var partialReleases = update.releases.slice(0, 10);

        if(partialReleases.length !== update.releases.length) {
            partialReleases.push({ id: -1, name: '<div class="show-all">Show all</div>' });
        }

        var releaseField = secondCell.add(new ComboBox({
            records: partialReleases,
            valueKey: 'id',
            legendKey: 'name'
        }));

        releaseField.on('Select', function(data) {
            if(data.record.id == -1) {
                releaseField.setRecords(releases);
                releaseField.selectIndex(0);
                releaseField.expand();
            }
            else {
                filteredLevels = [];
                var forcePartial = false;

                update.levels.forEach(function(anOL) {
                    var detectCFP = anOL.name.match(/\.CF[B-Z]\./); // NOTE wack because of V6 configured fix pack model for clients
                    anOL.legend = detectCFP ? '<span style="color:orange">'  + anOL.name + '</span>' : anOL.name;

                    if(anOL.level.release.id === data.record.id) {
                        if(detectCFP) {
                            forcePartial = true;
                        }

                        filteredLevels.push(anOL);
                    }
                });

                if(filteredLevels.length > 10 || forcePartial) {
                    var partialLevels = [];

                    filteredLevels.forEach(function(level) {
                        if(!level.name.match(/\.CF[B-Z]\./)) { // NOTE wack because of V6 configured fix pack model for clients
                            partialLevels.push(level);
                        }
                    });

                    if(partialLevels.length > 10) {
                        partialLevels = partialLevels.slice(0, 10);
                        partialLevels.push(filteredLevels[filteredLevels.length - 1]);
                    }

                    partialLevels.push({ id: -1, legend: '<div class="show-all">Show all</div>' });
                    levelField.setRecords(partialLevels);
                }
                else {
                    levelField.setRecords(filteredLevels);
                }

                levelField.selectIndex(0);
            }
        });

        var thirdCell = hbox.add(new View(), { width: '25%' });
        thirdCell.add(new Label('Level')).addType('top-lane');
        var levelField = thirdCell.add(new ComboBox({
            records: [],
            valueKey: 'id',
            legendKey: 'legend'
        }));
        levelField.on('Select', function(data) {
            if(data.record.id == -1) {
                levelField.setRecords(filteredLevels);
                levelField.selectIndex(0);
                levelField.expand();
            }
            else {
                this.emit('SearchOL', data.record);
            }
        }.bind(this));

        releaseField.selectIndex(0);
    }
});
