import View from 'oyat/UI/View';
import Link from 'oyat/UI/Link';
import HBox from 'oyat/UI/HBox';
import Label from 'oyat/UI/Label';
import User from '../User';
import './style.css';

export default View.extend({
    __construct: function() {
        this.__parent();
        this.addType('top-view');

        var wrapper = this.add(new View());
        wrapper.addType('header-wrapper');
        this.header = wrapper.add(new View());
        this.header.addType('header');

        var logoV = this.header.add(new View());
        logoV.addType('logo');
        logoV.setHTML('<a href="//www.3ds.com/" title="Dassault Systèmes" target="_blank"><span>Dassault Systèmes</span></a>');
	
		this.actions = this.header.add(new View());
		this.actions.addType('actions');

        this.warning = this.add(new View());
        this.warning.hide();
        this.warning.addType('warning');

        this.navigation = this.add(new View());
        this.navigation.addType('navigation');
    },
    setSession: function(session) {
        this.session = session;
        this.updateActions();
        this.updateWarning();
    },
    updateActions: function() {
        this.actions.clear();

        if(this.session.site) {
            if(this.session.sites.length > 1) {
                var switchLink = this.actions.add(new Link({ text: 'Change site' }));
                switchLink.addType('switch-site');
                switchLink.on('Click', this.emit.bind(this, 'OpenSites'));
            }

            this.session.site.headOffice = this.session.site.headOffice || {};

            var label = (this.session.site.headOffice.name || this.session.site.name);

            let city = this.session.site.headOffice.city || this.session.site.city;
            label += city ? ', ' + city : '';

            let country = this.session.site.headOffice.country || this.session.site.country;
            label += country ? ', ' + country : '';

            this.actions.add(new Label(label)).addType('current-site');
        }

        this.actions.add(new Label(this.session.user.name)).addType('user-name');

		this.ratingIcon = this.actions.add(new View());
		this.ratingIcon.addType('rating-icon fa fa-lg');

        // hide only for empty IB
        if(!this.session.feedback) {
            this.ratingIcon.hide();
        }

        this.ratingIcon.on('Click', this.emit.bind(this, 'OpenRating'));
		
        var helpIcon = this.actions.add(new View());
        helpIcon.addType('help-icon fa fa-question-circle');
        helpIcon.on('Click', this.emit.bind(this, 'OpenHelp'));

        var logoutButton = this.actions.add(new View());
        logoutButton.addType('logout-button');
        logoutButton.setHTML('<i class="fa fa-power-off"></i>');
        logoutButton.on('Click', this.emit.bind(this, 'Logout'));
    },
    updateWarning: function() {
        this.warning.hide();
        console.log(this.session);

        if(this.session.user.flags & 2) {
            this.warning.show();
            this.warning.setHTML('Download by employee must be used on DS premises only. You are not authorized to share deliverable with customers and partners.');
        }
    },
    buildNavigation: function(type) {
        this.navigation.clear();

        var supportedSoftwareLink = this.navigation.add(new View());
        supportedSoftwareLink.setText('My Supported Software');
        supportedSoftwareLink.addType('search');
        supportedSoftwareLink.on('Click', this.emit.bind(this, 'GoToSupportedSoftware'));

        var freeDownloadLink = this.navigation.add(new View());
        freeDownloadLink.setText('Free Download');
        freeDownloadLink.addType('free-download');
        freeDownloadLink.on('Click', this.emit.bind(this, 'GoToFreeDownload'));

        var ordersLink = this.navigation.add(new View());
        ordersLink.setText('My Orders');
        ordersLink.addType('orders');
        ordersLink.on('Click', this.emit.bind(this, 'GoToOrders'));

        switch(type) {
            case 'SupportedSoftware': supportedSoftwareLink.addType('active'); break;
            case 'FreeDownload': freeDownloadLink.addType('active'); break;
            case 'Orders': ordersLink.addType('active'); break;
        }
    }
});
